import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {catchError, mergeMap, takeUntil} from 'rxjs/operators';
import {NgForm, ValidationErrors} from '@angular/forms';
import {BuyCryptoService} from '../../../services/buy-crypto.service';
import {EurasiaBankPayload} from '../../../models/eurasia-bank-payload';
import {Subscription} from 'rxjs/internal/Subscription';
import {Observable} from 'rxjs/internal/Observable';
import {BuyCryptoState} from '../../../models/buy-crypto-state';
import {TranslateService} from '@ngx-translate/core';
import {TemplateResponse} from '../../../models/template-response';
import {environment} from '../../../../environments/environment';
import {of} from 'rxjs/internal/observable/of';
import {EukzSpendsDto} from '../../../services/eukz-spends';
import {ProfileState} from '../../../store/profile.state';
import {fromMobx} from '../../../store/rx-from-mobx';

type StepType = 'CHECK' | 'SELECT_TYPE' | 'BUY' | 'SELL' | 'IFRAME';
type OpType = 'BUY' | 'SELL';

interface IFaq {
  question: string;
  answer: string;
  expanded: boolean;
}

const CHECK_INTERVAL = 30 * 1000;

@Component({
  selector: 'app-buy-crypto',
  templateUrl: './buy-crypto.component.html',
  styleUrls: ['./buy-crypto.component.scss']
})
export class BuyCryptoComponent implements OnInit, OnDestroy {

  @ViewChild('iframe') iframe: ElementRef;

  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();

  currentStep: StepType = 'CHECK';

  opType: OpType = 'BUY';

  public isProdEnvironment = environment.name === 'prod';

  formData = {
    currency: 'KZT',
    cryptoCurrency: 'USDT',
    paymentMethod: 1,
    amount: null,
    iin: this._profileState.userProfile?.iin
  };

  faqs: IFaq[] = [
    {
      question: 'BUY_CRYPTO.HOW_TO_BUY_WITH_EURASIA_BANK',
      answer: 'BUY_CRYPTO.HOW_TO_BUY_WITH_EURASIA_BANK_ANSWER',
      expanded: false
    }
  ];

  bankForm: string = null;
  waitForBankForm = false;
  isBankFormLoadingError = false;

  waitForCheck = false;
  state: BuyCryptoState;
  spends: EukzSpendsDto;
  private _timerId: number;

  buyPrice: number;
  sellPrice: number;

  get fiatToCrypto() {
    if (!this.formData.amount || !this.buyPrice) { return null; }
    return (+(this.formData.amount / this.buyPrice).toFixed(2)).toString();
  }

  get fiatToCryptoTotal() {
    if (!this.formData.amount) { return null; }
    return (+(+this.formData.amount * 1.01).toFixed(2)).toString();
  }

  get cryptoToFiat() {
    if (!this.formData.amount || !this.sellPrice) { return null; }
    return (+(this.formData.amount * this.sellPrice).toFixed(2)).toString();
  }

  get cryptoToFiatTotal() {
    const converted = this.cryptoToFiat;
    if (!converted) { return null; }
    return (+(+converted * 0.985).toFixed(2)).toString();
  }

  constructor(public router: Router,
              public dataService: DataService,
              private _profileState: ProfileState,
              private _buyCryptoService: BuyCryptoService,
              private _translateService: TranslateService
    ) {
      if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
      this.dataService.getIsLightThemeEmitter()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(isLightTheme => {
          this.isLightTheme = isLightTheme;
        });
      this._buyCryptoService.getSpends().pipe(takeUntil(this.destroySubject$)).subscribe(
        (spends) => this.spends = spends,
      );
    }

  ngOnInit(): void {
    this.checkState();
    this.loadPrices();
    fromMobx(() => this._profileState.userProfile).pipe(takeUntil(this.destroySubject$)).subscribe(
      (data) => this.formData.iin = data.iin,
    );
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }

  loadPrices(): void {
    this.dataService.getExchangeRate('BUY').subscribe(price => this.buyPrice = price);
    this.dataService.getExchangeRate('SELL').subscribe(price => this.sellPrice = price);
  }

  checkState(): void {
    this.waitForCheck = true;
    this._buyCryptoService
      .check()
      .pipe(
        takeUntil(this.destroySubject$),
        )
      .subscribe(state => {
        this.state = state;
        if (!state) {
          this.currentStep = 'SELECT_TYPE';
          return;
        }

        this._timerId = setTimeout(() => this.checkState(), CHECK_INTERVAL);
      })
      .add(() => this.waitForCheck = false);
  }

  cancel(): void {
    if (this._timerId) {
      clearTimeout(this._timerId);
      this._timerId = undefined;
    }

    this._buyCryptoService
      .cancel(this.state.id, 'Canceled by user!')
      .pipe(
        takeUntil(this.destroySubject$),
        catchError(error => {
          return of();
        })
      )
      .subscribe(() => {
        this.currentStep = 'SELECT_TYPE';
      });
  }

  next(form?: NgForm): void {
    if (!form.valid) { return; }

    switch (this.currentStep) {
      case 'SELECT_TYPE':
        this.currentStep = this.opType;
        break;

      default:
        this.loadBankForm();
/*
        if (!this.isProdEnvironment) {
          this.currentStep = 'IFRAME';
        }
*/
        break;
    }
  }

  back(): void {
    this.currentStep = 'SELECT_TYPE';
    this.formData.amount = null;
  }

  loadBankForm(): void {
    let request: Observable<TemplateResponse>;

    const payload: EurasiaBankPayload = {
      amount: this.formData.amount,
      description: 'test',
      iin: this.formData.iin,
      from: null,
      to: null
    };

    switch (this.opType) {
      case 'BUY':
        payload.from = this.formData.currency;
        payload.to = this.formData.cryptoCurrency;
        request = this._buyCryptoService.getBuyCryptoTemplate(payload);
        break;

      case 'SELL':
        payload.from = this.formData.cryptoCurrency;
        payload.to = this.formData.currency;
        request = this._buyCryptoService.getSellCryptoTemplate(payload);
        break;
    }

    this.waitForBankForm = true;
    this.isBankFormLoadingError = false;
    request
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((bankForm) => {
/*
        if (this.isProdEnvironment) {
*/
        window.open(bankForm.url, '_blank');
        this.currentStep = 'CHECK';
        this.checkState();
/*
        } else {
          this.bankForm = bankForm.html;
          const win: Window = this.iframe.nativeElement.contentWindow;
          const doc: Document = win.document;
          doc.open();
          doc.write(this.bankForm);
          doc.close();
        }
*/
      }, (error) => {
        this.currentStep = 'CHECK';
        this.checkState();
        this.isBankFormLoadingError = true;
      })
      .add(() => this.waitForBankForm = false);
  }

  getCryptoAmountErrorMsg(errors: ValidationErrors): string {
    if (errors.hasOwnProperty('wallet')) {
      return this._translateService.instant('BUY_CRYPTO.ERROR.WALLET_NOT_FOUND');
    }

    if (errors.hasOwnProperty('walletBalance')) {
      return this._translateService.instant('BUY_CRYPTO.ERROR.NOT_ENOUGH_MONEY', {
        balance: (Math.trunc(errors.walletBalance * 100) / 100).toFixed(2)
      });
    }

    return this._translateService.instant('BUY_CRYPTO.ERROR.INVALID_CRYPTO_AMOUNT');
  }
}
