import {Component, Input, EventEmitter, Output} from '@angular/core';

import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ThemeService} from '../../services/theme.service';
import {LanguageState} from '../../store/language.state';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {
  @Input() opened: boolean;
  @Output() navigateEvent = new EventEmitter<string>();
  public openList = { profile: false, lang: false, account: false };
  public currentLang = this.translateService.currentLang;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  events: string[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
    public authService: AuthService,
    private router: Router,
    public themeService: ThemeService,
    public languageState: LanguageState
  ) {}

  navigate(url: string) {
    this.navigateEvent.emit(url);
  }

  public toggleList(list: string): void {
    this.openList[list] = !this.openList[list];
  }

  public selectLanguage(lang: string): void {
    window.localStorage.setItem('preferred_lang', lang);
    this.translateService.use(lang);
    this.openList.lang = false;
    this.currentLang = lang;
    this.languageState.setLanguage(lang);
    if (this.router.url.includes('identity-verification') && window.matchMedia('(max-width: 860px)').matches) {
      this.opened = false;
    }
  }

  public logout(): void {
    this.authService.logout();
    localStorage.removeItem('selected_currency');
    this.router.navigate(['/login']).then();
  }
}
