import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {WithdrawalComponent} from '../../../dialogs/withdrawal/withdrawal.component';
import {WithdrawalSuccessComponent} from '../../../dialogs/withdrawal-success/withdrawal-success.component';
import {SvgService} from '../../../services/svg.service';
import {AddWalletComponent} from '../../../dialogs/add-wallet/add-wallet.component';
import {WalletDepositComponent} from '../../../dialogs/wallet-deposit/wallet-deposit.component';
import {CurrencyInterface} from '../../../models/currency.model';
import {WalletBalanceInterface} from '../../../models/wallet-balance.model';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DepositWithdrawalInterface} from '../../../models/deposit-withdrawal.model';
import {DataService} from '../../../services/data.service';
import {MarketLastPriceInterface} from '../../../models/market-last-price.model';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {AddFiatComponent} from '../../../dialogs/add-fiat/add-fiat.component';
import {TickHistoryModel} from '../../../models/tick-history.model';
import {MyProfileInterface} from '../../../models/my-profile.model';
import {AlertComponent} from '../../../dialogs/alert/alert.component';
import {environment} from '../../../../environments/environment';
import {WalletCreatedComponent} from '../../../dialogs/wallet-created/wallet-created.component';
import {NotVerifiedComponent} from '../../../dialogs/not-verified/not-verified.component';
import {GeoState} from '../../../store/geo.state';
import {fromMobx} from '../../../store/rx-from-mobx';
import {ProfileState} from '../../../store/profile.state';

@Component({
  selector: 'app-wallets',
  templateUrl: './wallets.component.html',
  styleUrls: ['./wallets.component.scss']
})
export class WalletsComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  public isAuthorisedForFiatModal = false;
  public isAustralianPayementSysteme = false;
  public balances = Array<WalletBalanceInterface>(0);
  public nonZeroBalances = Array<WalletBalanceInterface>(0);
  public totalBalanceUSDT = 0;
  // public totalBalanceFIAT = 0;
  public totalDepositUSDT = 0;
  public totalWithdrawalsBalanceUSDT = 0;
  public totalWithdrawalsPersanalLimitUSDT = undefined;
  public totalWithdrawalsLimit1USDT = 1000;
  public totalWithdrawalsLimit2USDT = 10000;
  public totalDepositsLimit1USDT = 100;
  public totalWithdrawalsCurrentLimitUSDT = 0;
  public withdrawsAvailableLimit = undefined;
  public intervalTotalBalance: any;
  public intervalUpdateWallets: any;
  public history: Array<any>;
  public searchText: string;
  public hideZeroBal: boolean;
  public hideNoTransactionsBal: boolean;
  public whichOrdersToDisplay = '';
  public isUserCompletedProfileForm = false;
  public withdrawalsSpinned = [];
  public withdrawalsCancelled = [];
  public blockedMarkets = [];
  public errorMessage_cancelWithdrawal = '';
  public currenciesList: Array<CurrencyInterface>; // new
  // public selectedCurrency: string;
  public error;
  public isSpinnedStatus = true;
  public kycStatus: number;
  public isUploadedImages: number;
  public isGeoBlockedCountry = false;
  public isGeoBlockedCity = false;
  public isPending = true;
  public reCaptcha_version = environment.name || 'prod';
  public notVerifiedOpen = false;
  public tradeOpened = '';
  markets$ = this.dataService.marketsDetails$;

  x: any;
  destroySubject$: Subject<void> = new Subject();

  constructor(
    public svgService: SvgService,
    private dataService: DataService,
    private authService: AuthService,
    private geoState: GeoState,
    private profileState: ProfileState,
    private router: Router,
    public dialog: MatDialog) {
    this.loadIsUserCompletedProfileFormData();
    this.loadWithdrawsAvailableLimit();
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
    this.isAuthorisedForFiatModal = this.profileState.isAuthorisedForFiat;
    fromMobx(() => this.profileState.isAuthorisedForFiat)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isAuthorisedForFiatModal = data;
      });
  }

  ngOnInit(): void {
    if (this.reCaptcha_version === 'audev' || this.reCaptcha_version === 'auprod' || this.reCaptcha_version === 'dev') {
      this.isAustralianPayementSysteme = true;
    }
    this.isPending = true;
    // console.log('window.navigator', window.navigator);
    // console.log('window.location.origin', window.location.origin);

    this.getTicksHistory();
    this.totalWithdrawalsCurrentLimitUSDT = this.totalWithdrawalsPersanalLimitUSDT >= 0 ? this.totalWithdrawalsPersanalLimitUSDT : this.totalWithdrawalsLimit1USDT;
    // this.totalWithdrawalsCurrentLimitUSDT = this.totalWithdrawalsLimit1USDT;
    this.getBalances();
    this.getTransactions();
    this.getCurrenciesList();
    this.dataService.getCurrenciesListFromServer();
    this.dataService.SubscribeToUpdateBalances()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: any) => {
        if (data.u) {
          this.getBalances();
        }
      });
    this.intervalTotalBalance = window.setInterval(() => {
      if (this.nonZeroBalances && this.nonZeroBalances.length) {
        this.countTotalBalance();
      }
    }, 600000); // 10 mins
    this.geoState.loadBlackListCountriesIfEmpty();
    this.geoState.loadCountriesIfEmpty();
    this.getKycStatus();
    this.checkGeoForBlackListedCountry();
    this.checkIfResidenceCountryIsInBlackListAndIfCitizenshipIsInBlackList();
    // this.dataService.passMessageTypeForGlobalMessage(1);
    // this.dataService.setMessageTypeForGlobalMessage(1);
    this.profileState.loadUserProfileIfEmpty();
    this.getUserPersonalLimit();
    this.getBlockedMarkets();
    this.intervalUpdateWallets = setInterval(() => {
      // this.postUpdateWallets(this.dataService.getUserId());
      // this.getWallet();
    }, 10000);
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    window.clearInterval(this.intervalTotalBalance);
    // window.clearInterval(this.intervalUpdateWallets);
  }

  public blockUserActivity() {
/*
    if (environment.name === 'prod') {
      this.router.navigate(['/login']).then();
      this.dataService.setLoginIncomeErrorStatus(['isGeoBlockedCountry']);
      this.dataService.passLoginIncomeErrorStatus(['isGeoBlockedCountry']);
      this.authService.logout();
    }
*/
  }

  public checkIfResidenceCountryIsInBlackListAndIfCitizenshipIsInBlackList() {
    if (this.geoState.isResidenceCountryInBlackList || this.geoState.isCitizenshipInBlackList) {
      this.blockUserActivity();
    }
    fromMobx(() => this.geoState.isResidenceCountryInBlackList)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isResidenceCountryInBlackList => {
        // console.log('isResidenceCountryInBlackList 2', isResidenceCountryInBlackList);
        if (isResidenceCountryInBlackList) {
          this.blockUserActivity();
        }
      });
    fromMobx(() => this.geoState.isCitizenshipInBlackList)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isCitizenshipInBlackList => {
        if (isCitizenshipInBlackList) {
          this.blockUserActivity();
        }
      });
  }

  public checkGeoForBlackListedCountry() {
    this.isGeoBlockedCountry = this.geoState.isGeoCountryIsInBlackList;
    this.isGeoBlockedCity = this.geoState.isGeoCityIsInBlackList;
    if (this.geoState.isGeoCountryIsInBlackList || this.geoState.isGeoCityIsInBlackList) {
      this.blockUserActivity();
    }
    fromMobx(() => this.geoState.isGeoCountryIsInBlackList).pipe(takeUntil(this.destroySubject$))
      .subscribe(
        (isGeoBlockedCountry) => {
          this.isGeoBlockedCountry = isGeoBlockedCountry;
          this.blockUserActivity();
        }
      );
    fromMobx(() => this.geoState.isGeoCityIsInBlackList).pipe(takeUntil(this.destroySubject$))
      .subscribe(
        (isGeoBlockedCity) => {
          this.isGeoBlockedCity = isGeoBlockedCity;
          this.blockUserActivity();
        }
      );
  }

  public getCurrenciesList() {
    this.currenciesList = this.dataService.getSharedCurrenciesList();
    this.dataService.getSharedCurrenciesListEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((response: Array<CurrencyInterface>) => {
        this.currenciesList = response;
      });
  }

  public getKycStatus() {
    this.dataService.getUserKycStatus()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: any) => {
        // console.log(res);
        this.isUploadedImages = res.isUploaded;
        this.kycStatus = res.status;
        this.isSpinnedStatus = false;
        if (this.kycStatus === 1) {
          this.totalWithdrawalsCurrentLimitUSDT = this.totalWithdrawalsPersanalLimitUSDT >= 0
            ? this.totalWithdrawalsPersanalLimitUSDT : this.totalWithdrawalsLimit2USDT;
          // this.totalWithdrawalsCurrentLimitUSDT = this.totalWithdrawalsLimit2USDT;
          // console.log('totalWithdrawalsPersanalLimitUSDT', this.totalWithdrawalsPersanalLimitUSDT);
          // console.log('totalWithdrawalsCurrentLimitUSDT', this.totalWithdrawalsCurrentLimitUSDT);
        }
      }, () => {
        // console.log(error1);
        this.isSpinnedStatus = false;
      });
  }

  private getBalances(): void {
    this.isPending = true;
    this.withdrawalsSpinned = [];
    this.withdrawalsCancelled = [];
    this.dataService.getBalances()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: Array<WalletBalanceInterface>) => {
        this.balances = data.sort((v1, v2) => v2.valueUSDT - v1.valueUSDT);

        this.nonZeroBalances = this.balances.filter(item => {
          return (item.balance > 0 || item.pending > 0);
        });
        // console.log('this.nonZeroBalances', this.nonZeroBalances);
        // console.log('this.balances', this.balances);
        // need to decide what to do with reserved and total fields as they are not presented
        if (this.nonZeroBalances && this.nonZeroBalances.length) {
          this.countTotalBalance();
        }
        this.isPending = false;
      });
  }

  private countTotalBalance(): void {
    let _data: Array<TickHistoryModel>;
    _data = this.dataService.getTickHistory();
    if (_data && _data.length) {
      this.countTotalBalanceData(JSON.parse(JSON.stringify(_data)));
    }
    this.dataService.getTickHistoryEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(__data => {
        if (__data && __data.length) {
          this.countTotalBalanceData(JSON.parse(JSON.stringify(__data)));
        }
      });
  }

  private countTotalBalanceData(data: Array<TickHistoryModel>): void {
    this.totalBalanceUSDT = 0;
    // console.log('countTotalBalance', data);
    this.nonZeroBalances.forEach(nonZeroBalance => {
        this.totalBalanceUSDT += +nonZeroBalance.valueUSDT;
        // console.log('nonZeroBalance.balance USDT', nonZeroBalance.balance);
    });
  }

  private countTotalDeposits(_history: Array<DepositWithdrawalInterface>): void {
    let _data: Array<TickHistoryModel>;
    _data = this.dataService.getTickHistory();
    if (_data && _data.length) {
      this.countTotalDepositsData(JSON.parse(JSON.stringify(_history)), JSON.parse(JSON.stringify(_data)));
    }
    this.dataService.getTickHistoryEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(__data => {
        if (__data && __data.length) {
          this.countTotalDepositsData(JSON.parse(JSON.stringify(_history)), JSON.parse(JSON.stringify(__data)));
        }
      });
  }

  private countTotalDepositsData(_history: Array<DepositWithdrawalInterface>, data: Array<TickHistoryModel>): void {
    this.totalDepositUSDT = 0;
    const currencyPricesBTC = data.filter(item => item.name === 'BTC')[0];
    const BTCtoUSDTlastPrice = (currencyPricesBTC
      && (<MarketLastPriceInterface>currencyPricesBTC.USDT)
      && (<MarketLastPriceInterface>currencyPricesBTC.USDT).Last)
      ? (<MarketLastPriceInterface>currencyPricesBTC.USDT).Last : 0;
    // console.log('BTCtoUSDTlastPrice', BTCtoUSDTlastPrice);

    const deposits = this.getTransactionsHistoryByType(_history, 'Deposit');
    // console.log('deposits', deposits);
    deposits.forEach((transaction: DepositWithdrawalInterface) => {
      if (transaction.currency !== 'USDT') {
        const currencyPrices = data.filter(item => {
          return item.name === transaction.currency;
        })[0];
        // console.log('currencyPrices', currencyPrices);
        const currencyPricesUSDT = (
          (<TickHistoryModel>currencyPrices
            && (<MarketLastPriceInterface>currencyPrices.USDT) && (<MarketLastPriceInterface>currencyPrices.USDT).Last)
            ? +(<MarketLastPriceInterface>currencyPrices.USDT).Last
            : ((<TickHistoryModel>currencyPrices
            && (<MarketLastPriceInterface>currencyPrices.BTC) && (<MarketLastPriceInterface>currencyPrices.BTC).Last)
            ? (<MarketLastPriceInterface>currencyPrices.BTC).Last * BTCtoUSDTlastPrice : 0)
        );
        // console.log('currencyPricesUSDT.last ' + transaction.currency, currencyPricesUSDT);
        this.totalDepositUSDT += currencyPricesUSDT * (+transaction.quantity);
      } else {
        this.totalDepositUSDT += +transaction.quantity;
        // console.log('nonZeroBalance.balance USDT', nonZeroBalance.balance);
      }
    });
    if (this.totalDepositUSDT && ((this.totalDepositUSDT) > this.totalDepositsLimit1USDT)) {
      // this.addFreeCoinsToUserWallet();
    }
    // console.log('totalDepositUSDT=' + this.totalDepositUSDT);
  }

  // public addFreeCoinsToUserWallet() {
  //   this.dataService.getUserProfile()
  //     .pipe(takeUntil(this.destroySubject$))
  //     .subscribe((res: MyProfileInterface) => {
  //       // console.log(res);
  //       this.dataService.addFreeCoinsToUserWallet(res.id)
  //         .pipe(takeUntil(this.destroySubject$))
  //         .subscribe(result => {
  //           console.log(result);
  //           this.dataService.passMessageTypeForAlertModal(1);
  //           this.dataService.setMessageTypeForAlertModal(1);
  //
  //         }, error => {
  //           console.log(error);
  //         });
  //     });
  // }

  private getTransactions(): void {
    this.isPending = true;
    this.dataService.getDepositsWithdrawals()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        // console.log(data);
        const deposits = [];
        const withdrawals = [];
        if (data.deposits && (<Array<DepositWithdrawalInterface>>data.deposits).length) {
          const ds = data.deposits;
          for (let i = 0; i < ds.length; i++) {
            deposits.push(Object.assign({}, ds[i], {'type': 'Deposit'}));
          }
        }
        if (data.withdrawals && (<Array<DepositWithdrawalInterface>>data.withdrawals).length) {
          const ws = data.withdrawals;
          for (let i = 0; i < ws.length; i++) {
            withdrawals.push(Object.assign({}, ws[i], {'type': 'Withdrawal'}));
          }
        }
        // console.log('withdrawals', withdrawals);
        this.history = withdrawals
          .concat(deposits)
          .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at));
        const withdrawsHistory = this.getTransactionsHistoryByType(this.history, 'Withdrawal');
        this.countTransactionsTotalBalance(withdrawsHistory);
        this.countTotalDeposits(JSON.parse(JSON.stringify(this.history)));
        this.isPending = false;
        // console.log('withdrawsHistory', withdrawsHistory);
        // console.log('history', this.history);
      });
  }

  public countTransactionsTotalBalance(_history: Array<DepositWithdrawalInterface>, date_start?: Date, date_end?: Date) {
    const dateEnd = date_end || new Date();
    const dateTemp = date_end || new Date();
    // const dateStart = date_start || new Date(dateTemp.setDate(dateTemp.getDate() - 1));
    const dateStart = date_start || new Date(dateTemp.setFullYear(dateTemp.getFullYear() - 1));
    const historySorted = _history.sort((a, b) => {
      return parseFloat(a.created_at) - parseFloat(b.created_at);
    });
    const historySortedFilteredByDates = [];
    historySorted.forEach(transaction => {
      if (transaction.status === 'COMPLETED'
        && ((new Date(transaction.created_at)).getTime() > +dateStart.getTime())
        && ((new Date(transaction.created_at)).getTime() < +dateEnd.getTime())) {
        historySortedFilteredByDates.push(transaction);
      }
    });
    // console.log('historySortedFilteredByDates', historySortedFilteredByDates);
    // console.log('dateStart=', dateStart);
    // console.log('dateEnd=', dateEnd);

    let _data: Array<TickHistoryModel>;
    _data = this.dataService.getTickHistory();
    if (_data && _data.length) {
      this.countTransactionsTotalBalanceData(
        JSON.parse(JSON.stringify(historySortedFilteredByDates)),
        JSON.parse(JSON.stringify(_data))
      );
    }
    this.dataService.getTickHistoryEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(__data => {
        if (__data && __data.length) {
          this.countTransactionsTotalBalanceData(
            JSON.parse(JSON.stringify(historySortedFilteredByDates)),
            JSON.parse(JSON.stringify(_data))
          );
        }
      });
  }

  public getTotalInUSDT() {
    let totalValueInUSDT = 0;
    this.balances.forEach(balance => totalValueInUSDT += balance.valueUSDT);
    return totalValueInUSDT;
  }

  public countTransactionsTotalBalanceData(historySortedFilteredByDates: Array<DepositWithdrawalInterface>, data: Array<TickHistoryModel>) {
    this.totalWithdrawalsBalanceUSDT = 0;
    const currencyPricesBTC = data.filter(item => item.name === 'BTC')[0];
    const BTCtoUSDTlastPrice = (currencyPricesBTC
      && (<MarketLastPriceInterface>currencyPricesBTC.USDT)
      && (<MarketLastPriceInterface>currencyPricesBTC.USDT).Last)
      ? (<MarketLastPriceInterface>currencyPricesBTC.USDT).Last : 0;
    // console.log('countTotalBalance', data);
    historySortedFilteredByDates.forEach((transaction: DepositWithdrawalInterface) => {
      if (transaction.currency !== 'USDT') {
        const currencyPrices = data.filter(item => {
          return item.name === transaction.currency;
        })[0];
        // console.log(currencyPrices);
        const currencyPricesUSDT = (
          (<TickHistoryModel>currencyPrices &&
            (<MarketLastPriceInterface>currencyPrices.USDT) && (<MarketLastPriceInterface>currencyPrices.USDT).Last)
            ? +(<MarketLastPriceInterface>currencyPrices.USDT).Last
            : ((<TickHistoryModel>currencyPrices
            && (<MarketLastPriceInterface>currencyPrices.BTC) && (<MarketLastPriceInterface>currencyPrices.BTC).Last)
            ? (<MarketLastPriceInterface>currencyPrices.BTC).Last * BTCtoUSDTlastPrice : 0)
        );
        // console.log('currencyPricesUSDT.last ' + transaction.currency, currencyPricesUSDT);
        this.totalWithdrawalsBalanceUSDT += currencyPricesUSDT * (+transaction.quantity);
      } else {
        this.totalWithdrawalsBalanceUSDT += +transaction.quantity;
        // console.log('nonZeroBalance.balance USDT', nonZeroBalance.balance);
      }
    });
    // setTimeout(() => {
    //   const totalWithdrawalsLimit = this.totalWithdrawalsPersanalLimitUSDT >= 0
    //     ? this.totalWithdrawalsPersanalLimitUSDT : this.totalWithdrawalsLimit2USDT;
    //   if (this.totalWithdrawalsBalanceUSDT > totalWithdrawalsLimit) {
    //     this.dataService.passMessageTypeForAlertModal(5);
    //     this.dataService.setMessageTypeForAlertModal(5);
    //   }
    // }, 0);
    // this.dataService.calculateWithdrawsAvailableLimit(this.totalWithdrawalsBalanceUSDT, this.totalWithdrawalsCurrentLimitUSDT);
    // console.log('totalWithdrawalsBalanceUSDT=' + this.totalWithdrawalsBalanceUSDT);
  }

  public getTransactionsHistoryByType(history: Array<DepositWithdrawalInterface>, type?: any): Array<DepositWithdrawalInterface> {
    if (type) {
      return history.filter(item => {
        return item.type.toLowerCase() === type.toLowerCase();
      });
    } else {
      return history;
    }
  }

  private postUpdateWallets(userId: number) {
    this.dataService.postUpdateWallets(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        },
        error => {
          console.log(error);
        });
    this.dataService.postCheckPendingWalletsStatus(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        },
        error2 => {
          console.log(error2);
        });
  }

  private getWallet() {
    const currency = this.profileState.walletToCheck;

    if (currency) {
      this.dataService.getWallets()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((data) => {
            const wallet = data.find(x => x.currency == currency);
            if (wallet && wallet.public_key) {
              this.openWalletCreated();
            }
          },
          error => {
            console.log(error);
          });
    }
  }

  public openWithdraw(currency, walletBalance): void {
    this.error = '';
    this.dialog.open(WithdrawalComponent, {
      data: {
        dialogFor: 'withdraw-confirmation',
        currency, walletBalance,
        blockedMarkets: this.blockedMarkets || []
      }
    })
      .afterClosed().subscribe(res => {
      if (res && res.success) {
        this.dialog.open(WithdrawalSuccessComponent);
        this.getBalances();
        this.getTransactions();
      }
    }, err => {
      this.error = err.error.message || err.message;
      console.log(err);
    });
  }

  public openDeposit(key, currency, tag) {
    this.dialog.open(WalletDepositComponent,
      {data: {key, currency, tag, blockedMarkets: this.blockedMarkets || []}});
  }

  public openAddWallet() {
    this.dialog.open(AddWalletComponent).afterClosed()
      .subscribe(() => this.getBalances());
  }

  public openWalletCreated() {
    this.dialog.open(WalletCreatedComponent).afterClosed()
      .subscribe(() => {
        // this.getBalances()
      });
  }

  public openNotVerified() {
    this.dialog.open(NotVerifiedComponent).afterClosed()
      .subscribe(() => {
        this.router.navigateByUrl('user-settings/identity-verification').then();
      });
    this.notVerifiedOpen = true;
  }

  public openAddFiat() {
    this.dialog.open(AddFiatComponent).afterClosed()
      .subscribe(() => {
        // this.getBalances();
      });
  }

  public addSpinnedWithdrawals(data: number) {
    this.withdrawalsSpinned.push(data);
    // console.log('withdrawalsSpinned', this.withdrawalsSpinned);
  }

  public addCancelledWithdrawals(data: number) {
    this.withdrawalsCancelled.push(data);
    // console.log('withdrawalsCancelled', this.withdrawalsCancelled);
  }

  public isCancelledWithdrawals(data: number): boolean {
    return this.withdrawalsCancelled.includes(data);
  }

  // public cancelWithdrawal() {
  public cancelWithdrawal(withdrawal: number) {
    if (this.profileState.userId === 50) {
      // console.log(withdrawal);
      this.dataService.cancelWithdrawal(withdrawal)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(
          res => {
            console.log(res);
            this.getTicksHistory();
            // this.getOrders();
          },
          err => {
            if (err.error.errorCode === '400_31') {
              this.errorMessage_cancelWithdrawal = err.error.message;
            }
            try {
              this.openAlertModal(8, err.error.message + ', ' + (err.error.errorCode || ''));
            } catch (e) {
              console.log(e);
            }
            console.log(err);
          }
        );
    }
  }

  public openAlertModal(messageType: number, messageText?: string) {
    if (messageType) {
      this.dialog.open(AlertComponent, {
        data: {'messageType': messageType, 'messageText': messageText || 'no message'}
      });
    }
  }

  public loadIsUserCompletedProfileFormData() {
    fromMobx(() => this.profileState.isUserCompletedProfileForm)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isUserCompletedProfileForm = data;
        // console.log('isUserCompletedProfileForm 2', this.isUserCompletedProfileForm);
      });
  }

  public loadWithdrawsAvailableLimit() {
    fromMobx(() => this.profileState.withdrawsAvailableLimit)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.withdrawsAvailableLimit = data;
        // console.log('this.withdrawsAvailableLimit', this.withdrawsAvailableLimit);
      });
  }

  private getTicksHistory(): void {
    this.dataService.getTicksHistory()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: Array<TickHistoryModel>) => {
        this.dataService.passTickHistory(data);
        this.dataService.setTickHistory(data);
      }, error => {
        console.log(error);
      });
  }

  public getUserPersonalLimit() {
    fromMobx(() => this.profileState.userProfile)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((response: MyProfileInterface) => {
        if (response && response.custom_fee && response.custom_fee.max_withdrawal >= 0) {
          this.setWithdrawalsPersonalLimit(+response.custom_fee.max_withdrawal, 3);
        } else {
          // this.setWithdrawalsPersonalLimit(+this.totalWithdrawalsCurrentLimitUSDT, 4);
        }
      });
  }

  setWithdrawalsPersonalLimit(newLimit: number, id?: any) {
    // console.log('newLimit' + '*' + id, newLimit);
    this.totalWithdrawalsPersanalLimitUSDT = newLimit;
    this.totalWithdrawalsCurrentLimitUSDT = this.totalWithdrawalsPersanalLimitUSDT;
    this.profileState.calculateWithdrawsAvailableLimit(this.totalWithdrawalsBalanceUSDT, newLimit);
  }

  public getBlockedMarkets(): void {
    this.dataService.getBlockedMarkets()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: Array<string>) => {
        if (res.length) {
          this.blockedMarkets = res.map(item => {
            return item.split('-')[1];
          });
        }
      }, error => {
        console.log(error);
      });
  }

    protected readonly Number = Number;
}
